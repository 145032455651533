import { type UseQueryOptions, keepPreviousData, useInfiniteQuery, useQuery } from "@tanstack/vue-query"

import { StocksAllocation } from "@finq/stocks/types"
import { PortfolioType } from "@finq/stocks/types/stocks-portfolios"

import {
  GetStocksParams,
  GetStocksPortfoliosParams,
  StocksService,
} from "../../../../../packages/stocks/composables/services/stocks.service"

type QueryOptions<T> = Partial<UseQueryOptions<T>>

export type GetStockPortfoliosTypes = Awaited<ReturnType<typeof StocksService.getStocksPortfoliosTypes>>
export function useStocksPortfoliosTypes(options: QueryOptions<GetStockPortfoliosTypes> = {}) {
  return useQuery<GetStockPortfoliosTypes>({
    queryKey: ["stocks-portfolios-types"],
    queryFn: ({ signal }) => StocksService.getStocksPortfoliosTypes({ signal }),
    staleTime: Infinity,
    refetchOnReconnect: false,
    placeholderData: arrayInLength(4).map((i: number) => ({ id: String(i) })) as GetStockPortfoliosTypes,
    ...options,
  })
}

export type GetStocksReturnType = Awaited<ReturnType<typeof StocksService.getStocks>>
export const DEFAULT_STOCK_SIZE_PARAM = 5

export function createLockedStocks(options: { startIndex: number; count: number; increaseIndex?: boolean }) {
  const { startIndex, count, increaseIndex = true } = options

  return Array(count)
    .fill(null)
    .map((_, index) => {
      const rankOrder = increaseIndex ? startIndex + index + 1 : startIndex + index
      return { rankOrder, locked: true }
    }) as GetStocksReturnType["data"]
}

export function getStocksFallbackData(options: {
  totalSize: number
  lockedCount: number
  increaseIndex?: boolean
}) {
  const { totalSize = DEFAULT_STOCK_SIZE_PARAM, lockedCount = 2, increaseIndex = false } = options

  if (lockedCount > totalSize) {
    throw new Error("Locked count cannot exceed total size")
  }

  const unlockedCount = totalSize - lockedCount
  const unlockedStocks = Array(unlockedCount)
    .fill(null)
    .map((_, index) => ({ rankOrder: index + 1 }))

  const lockedStocks = createLockedStocks({
    startIndex: unlockedCount + 1,
    count: lockedCount,
    increaseIndex,
  })

  return [...unlockedStocks, ...lockedStocks] as GetStocksReturnType["data"]
}

export function useSNP500Ranking(
  params?: GetStocksParams,
  options: QueryOptions<GetStocksReturnType> & {
    fallbackData?: ReturnType<typeof getStocksFallbackData>
  } = {}
) {
  return useQuery<GetStocksReturnType>({
    queryKey: ["snp500-ranking"],
    queryFn: ({ signal }) =>
      StocksService.getStocks({
        signal,
        params: {
          size: DEFAULT_STOCK_SIZE_PARAM,
          ...params,
        },
      }),
    staleTime: Infinity,
    refetchOnReconnect: false,
    placeholderData: { data: options?.fallbackData } as GetStocksReturnType,
    ...options,
  })
}

export type GetStockPortfolios = Awaited<ReturnType<typeof StocksService.getStocksPortfoliosYields>>
export const useStocksPortfolioYields = (
  params: ComputedRef<GetStocksPortfoliosParams>,
  options: QueryOptions<GetStockPortfolios> = {}
) => {
  return useQuery<GetStockPortfolios>({
    queryKey: ["stocks-portfolios", params],
    queryFn: ({ signal }) => StocksService.getStocksPortfoliosYields({ signal, params: params.value }),
    refetchOnReconnect: false,
    placeholderData: keepPreviousData,
    staleTime: Infinity, // 1 day,
    ...options,
  })
}
export type GetStockById = Awaited<ReturnType<typeof StocksService.getStockById>>
export const useStockById = (id: string, options: QueryOptions<GetStockById> = {}) => {
  const { setCurrentStock } = useStocksStore()
  return useQuery({
    queryKey: ["stock", id],
    placeholderData: () => ({ data: arrayInLength(5).map((i) => ({ id: i })) }) as unknown as GetStockById,

    queryFn: async ({ signal }) => {
      const data = await StocksService.getStockById(id, { signal })
      setCurrentStock(data)

      return data
    },
    staleTime: Infinity, // 1 day,
  })
}

export type GetStocksAllocation = Awaited<ReturnType<typeof StocksService.getPortfoliosAllocation>>
export function useStocksAllocation(
  params: { portfolioType: PortfolioType; size?: number } = { portfolioType: "long10", size: 3 },
  options?: QueryOptions<GetStocksAllocation>
) {
  return useQuery<GetStocksAllocation>({
    queryKey: ["StocksService.getPortfoliosAllocation"],
    queryFn: ({ signal }) =>
      StocksService.getPortfoliosAllocation({
        signal,
        params: {
          portfolioType: params.portfolioType,
          size: 3,
        },
      }),
    placeholderData: () =>
      ({
        data: Array.from({ length: 3 }, (_, i) => ({
          asset: { rankOrder: i + 1 },
          price: 100,
          priceChange: 0.1,
        })),
      }) as unknown as PaginationResponse<StocksAllocation[]>,
    ...options,
  })
}

export type GetStocksSectors = Awaited<ReturnType<typeof StocksService.getSectors>>
export function useStocksSectors(options?: QueryOptions<GetStocksSectors>) {
  const query = useQuery<GetStocksSectors>({
    queryKey: ["StocksService.getSectors"],
    queryFn: ({ signal }) => StocksService.getSectors({ signal }),
    ...options,
  })

  function getSectorById(id: string) {
    return query.data.value?.find((sector) => sector.id === id)
  }

  return { ...query, getSectorById }
}
